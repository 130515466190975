@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Oswald:wght@200..700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Radio+Canada:ital,wght@0,300..700;1,300..700&display=swap');
@font-face {
    font-family: RallyBlade;
    src: url('./fonts/rallyblade.otf') format("opentype");
}
@font-face {
    font-family: RallySymbol;
    src: url('./fonts/rallysymbols.otf') format("opentype");
}


.rallyBlade{
    font-family: 'RallyBlade', serif;
}
.rallySymbol{
    font-family: 'RallySymbol', serif;
}

.oswald{
    font-family: "Oswald", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}
$oswald-weight: 200,300,400,500,600,700;
@each $weight in $oswald-weight{ .oswald-#{$weight}{ font-weight: #{$weight}; } }