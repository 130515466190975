@import 'bootstrap/dist/css/bootstrap.min.css';
@import './fonts.scss';
@import './sizes.scss';
@import './colors.scss';
@import './animations.scss';

html,
body {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
  background-color: var(--bgColor);
}

#root {
  height: 100%;
}

.error{
  color: #ff0000;
  font-weight: bold;
  position: absolute;
  bottom: -4px;
  left: 6px;
  z-index: 10;
}
.error2{
  color: #ff0000;
  font-weight: bold;
}
.success{
  color: #2c7a01;
  font-weight: bold;
}

.loginLayout {
  background-image: url('../../public/images/wallpaper.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.topBar .nav-link {
  margin-right: 5px;
}

.login-button {
  background-color: #525050;
  color:#fff;
  border-radius: 10px;
  transition: var(--default_transition);
}
.login-button:hover {
  background-color: #525050;
  color:#fff;
  border-radius: 10px;
  transform: scale(1.05);
}

.register-button {
  --bs-btn-border-color: #2c7a01;
  --bs-btn-hover-border-color: #2c7a01;
  background: #2c7a01;
  background-image: radial-gradient(150% 160% at 50% 15%, hsla(0, 0%, 100%, .6) 0, transparent 30%);
  color:#fff;
  border-radius: 10px;
  transition: var(--default_transition);
}
.register-button:active {
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2c7a01;
  --bs-btn-active-border-color: #2c7a01;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.register-button:hover {  
  background-color: #2c7a01;  
  color:#fff;
  border-radius: 10px;
  transform: scale(1.05);
}

.active.carousel-item::after{
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 150px;
  background: linear-gradient(to bottom, rgba(255,255,255,0), var(--bgColor));
  z-index: 6;
}

.info-frame{
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255,255,255,0.5);
}
.caption{
  color: #fff;
}
.caption-title{
  font-size: 24px;
  font-family: Oswald;
  padding-bottom: 15px;
  text-align: left;
}
.caption-text{
  font-size: 16px;
  font-family: Oswald;
  text-align: left;
}
.caption-image{
  width: 100%;
  border-radius: 50%;
}


.sectionTitle-frame{
  display: flex;
  align-items: center;
}
.sectionTitle{
  color: rgba(255,255,255,0.5);
  padding-right: 10px;
  text-transform: uppercase;
  font-family: 'Oswald';
}

.sectionBars::before{
  content: "";
  width: .0625rem;
}
.sectionBars::after{
  content: "";
  width: .0625rem;
}

.sectionBars{
  background: rgba(255,255,255,0.5);
  flex: 1 1;
  height: .125rem;
  position: relative;
}

/*StartScreen*/
.app{
  color:#fff;
  position:absolute;
  top: 57px;
  left:0;
  right:0;
  bottom:0;
  z-index:3;
}

/*Profile*/
.profile-user-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.profile-user-box .userName{
  font-family: "Oswald";
  font-size:22px;
  font-weight: bold;
  position: relative;
  top: -18px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255,255,255,0.5);
}

.profile-user-box .userName .displayLevel .levelName{
  font-size: 16px;
  color: var(--orange-50);
  font-weight: bold;
}
.profile-user-box .userName .displayLevel .levelProgress{
  position:relative;
  top: 4px;
  left: 10px;
  border-radius: 0 !important;
}

.statisticBoard{
  background-color: rgba(0,0,0,0.7);
  min-height: 250px;
  border-radius: 10px;
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.2);
  padding-top: 10px;
  padding-bottom: 10px;
}
.trophyBoard{
  background-color: rgba(0,0,0,0.7);
  min-height: 85px;
  border-radius: 10px;
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.2);
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: rgba(255,255,255,0.5);
  font-family: oswald;
}

/*PreGame innen*/
.pregameCard{
  background-color: rgba(0,0,0,0.7);
  border-radius: 10px;
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.2);
  padding-top: 10px;
  color: rgba(255,255,255,0.5);
  
  
  /*background-color: rgba(0, 0, 0, 0.256);
  */
}
.pregameCard .card-text{
  text-align: center;
  padding-top:20px;
}
.pregameCard .card-text button{
  margin-top: 20px;
  border-radius: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-family: 'oswald';
  font-size: 22px;
  font-weight: 700;
  background-color: var(--orange-50);
  border: 1px solid var(--orange-50);
  &:hover{
    background-color: var(--orange-70);
    border: 1px solid var(--orange-70);
  }
}

/*Game innen*/
.container-fluid {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
  display: none !important;
}

.menuBox{
  position:absolute;
  bottom: 145px;
  left: 45px;
}
.menuBox-list{
  --bs-list-group-color: #fff;
  --bs-list-group-bg: transparent;
  --bs-list-group-disabled-bg: transparent;
  --bs-list-group-disabled-color: rgba(255,255,255,0.5);
  --bs-list-group-border-color:transparent;
}
.menuBox-list .list-group-item{
  font-size: 36px;
  transition: var(--default_transition);
  text-shadow: 2px 2px rgba(0,0,0,0.85);
  font-family: Oswald;
}
.menuBox-list .list-group-item:hover{
  transform: scale(1.1);
}

.profileBox{
  position: absolute;
  top: -50px;
  right: 40px;
  z-index: 10;
}
.profileBox button{
  background-color: transparent;
  border: transparent;
  color: #fff;
  transition: var(--default_transition);
}
.profileBox button:hover{
  background-color: transparent;
  border: transparent;
  color: #fff;
  transform: scale(1.1);
}
.profileBox .lvl-text{
  color: #ab9a00;
  font-weight: bold;
}
progress{
  border-radius: 10px;
}
progress::-webkit-progress-bar{
  border-radius: 10px;
}
progress::-webkit-progress-value{
  background: var(--orange-50);
  border-radius: 10px;
}

.profileMenu.list-group, .profileMenu.list-group > .list-group-item{
  border: 0px solid transparent;
  color: #fff;
  text-align: left;
  background-color: #000;
  transition: var(--default_transition);
}
.profileMenu.list-group > .list-group-item:hover{
  transform: scale(1.1);
}

/*Modal*/
.modalTitle{
  font-weight: bold;
  font-size:24px;
}
.custom-modal .modal-content{
  background-color: var(--bgColor);
  color:#fff;
}
.upload-circle-base{
  display:flex;
  justify-content: center;
  align-items: center;
}
.upload-circle {
  margin-top: 20px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #fff;
  color: rgba(0,0,0,0.8);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}
.preview-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

/*Footer*/
.footer{
  color: rgba(255,255,255,0.5);
  height: 50px;
}
.footer a{
  color: rgba(255,255,255,0.5);
  text-decoration: none;
  font-weight: bold;
}


/*GameViewport*/
.guessrMap, .guessrButton{
  transition: ease-in-out 0.5s all;
}
.guessrMap{
  box-shadow: 3px 3px 6px rgba(0,0,0,0.85);
}
.resultModal .modal-header, .resultModal .modal-body{
  background-color: var(--bgColor);
  color: rgba(255,255,255,0.5);
}

/*Scoreboards*/
.scoreBoard{
  border-bottom: 0 !important;
}
.scoreBoard .nav-item .nav-link, .scoreBoard .nav-item .nav-link:hover{
  border-radius: 0;
  color:#fff;
  background-color: rgba(0, 0, 0, 0.256);
}
.score-group{
  --bs-list-group-border-radius: 0;
  border-radius: 0 !important;
}
.scoreBoard .nav-item .nav-link{
  border: 1px solid transparent;
}
.scoreBoard .nav-item .nav-link.active{
  --bs-btn-border-color: var(--orange-50);
  --bs-btn-hover-border-color: var(--orange-50);
  background: var(--orange-50);
  background-image: radial-gradient(150% 160% at 50% 15%, hsla(0, 0%, 100%, .6) 0, transparent 30%);
  color:#000;
  border-radius: 0px;
  transition: var(--default_transition);
}
.scoreBoardItem{
  border-radius: 0;
  border: 0;
  background: rgba(0,0,0,0.670) !important;
  color: #fff;
  &:not(:first-child){
    border-top: 1px solid rgba(255,255,255,0.5);
  }
}


/*Update GoogleLogin*/
.gAuth{
  margin-bottom: 5px !important;
  position:relative;
  top:-5px;
}
.privacy-content-box{
  margin-top:1.5rem;
  border-radius: 1rem;
  box-shadow: 0 .25rem 2.75rem rgba(32, 17, 46, .2), 0 1.125rem 2.25rem -1.125rem rgba(0, 0, 0, .24), 0 1.875rem 3.75rem -.625rem rgba(0, 0, 0, .16);
  padding: 1.5rem;
}



@import './overrides.scss';


.resultModal .modal-header .btn-close{
  --bs-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e);
}